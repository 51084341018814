import "./../css/player.scss";
import H5AP from "./player/single";
import H5AP_Plalist from "./player/playlist";
const H5AP_Obj = new H5AP();
const H5AP_Plalist_Obj = new H5AP_Plalist();

(function ($) {
  $(document).ready(function () {
    // if script not exists or extract
    const mainScript = document.getElementById('h5ap-player-js');
    if(!mainScript){
      const ioScript = document.createElement('script');
      ioScript.src = window?.h5apPlayer?.plyrio_js;
      document.getElementsByTagName("head")[0].appendChild(script);

      var script = document.createElement("script");
				script.src = window?.h5apPlayer?.plyr_js;
				document.getElementsByTagName("head")[0].appendChild(script);
    }


    /**
     * for single video
     */
    const h5apAudios = document.querySelectorAll(".h5ap_standard_player");
    Object.keys(h5apAudios).map((item) => {
      const audioPlayer = $(h5apAudios[item]);
      let options = audioPlayer.data("options");
      options = options == "{" ? {} : options;
      options.poster = options.poster ?? audioPlayer.data("poster");
      options.source = options.source ?? audioPlayer.data("song");
      options.skin = options.skin ?? audioPlayer.data("skin");
      options.title = options.title ?? audioPlayer.data("title");
      // const infos = "";
      if (options) H5AP_Obj.audioPlayer(audioPlayer, options);
    });

    
    H5AP_Obj.stickyPlayer2($(".h5ap_sticky_player"));

    /**
     * for playlist
     */

    const h5apPlaylist = document.querySelectorAll(".audioPlalist");
    Object.keys(h5apPlaylist).map((item) => {
      const playlist = $(h5apPlaylist[item]);
      H5AP_Plalist_Obj.audioPlylist(playlist);
    });

    /**
     * for blue playlist
     */
    const h5apHExtensive = document.querySelectorAll(".hextensive");
    Object.keys(h5apHExtensive).map((item) => {
      const playlist = $(h5apHExtensive[item]);
      H5AP_Plalist_Obj.bluePlaylist(playlist);
    });

    /**
     * for quick Player
     */
    const quickPlayers = document.querySelectorAll(".h5ap_quick_player");
    Object.keys(quickPlayers).map((item) => {
      const quickPlayer = $(quickPlayers[item]);
      H5AP_Obj.quickPlayer(quickPlayer);
    });

    // Single Play button
    const single_player = $(".h5ap_single_button");
    single_player.map((index, item) => {
      $(item).on("click", ".play", function () {
        $(item).find("audio")[0].play();
        $(item).find(".play").hide();
        $(item).find(".pause").show();
      });

      $(item).on("click", ".pause", function () {
        $(item).find("audio")[0].pause();
        $(item).find(".play").show();
        $(item).find(".pause").hide();
      });
    });
  });

  /**
   * This is for Elementor
   */
  $(window).on("elementor/frontend/init", function () {
    //this is for Stamp Auudio Player
    elementorFrontend.hooks.addAction("frontend/element_ready/StampAudioPlayer.default", function (scope, $) {
      const players = $(scope).find(".stampAudioPlayer");
      players.map((index, item) => {
        item = $(players[index]);
        let options = $(item).data("option");
        options.source = options.source ?? $(item).data("song");
        options.poster = options.poster ?? $(item).data("poster");
        H5AP_Obj.audioPlayer(item[0], options);
      });
    });

    // this is form fusion player
    elementorFrontend.hooks.addAction("frontend/element_ready/FusionAudioPlayer.default", function (scope, $) {
      const players = $(scope).find(".h5ap_fusion_player");
      players.map((index, item) => {
        item = $(players[index]);
        const options = $(item).data("option");
        options.source = options.source ?? $(item).data("song");
        options.poster = options.poster ?? $(item).data("poster");
        H5AP_Obj.audioPlayer(item[0], options);
      });
    });

    //This is for simple audio player
    elementorFrontend.hooks.addAction("frontend/element_ready/SimpleAudioPlayer.default", function (scope, $) {
      const players = $(scope).find(".h5ap_standard_player");
      players.map((index, item) => {
        item = $(players[index]);
        const options = $(item).data("options");
        options.source = options.source ?? $(item).data("song");
        options.poster = options.poster ?? $(item).data("poster");
        H5AP_Obj.audioPlayer(item[0], options);
      });
    });
    //This is for Playlist
    elementorFrontend.hooks.addAction("frontend/element_ready/PlaylistAudioPlayer.default", function (scope, $) {
      const playlist = $(scope).find(".audioPlalist");
      const extensive = $(scope).find(".hextensive");
      if (playlist.length > 0) {
        playlist.map((index, item) => {
          item = $(playlist[index]);
          const options = $(item).data("options");
          H5AP_Plalist_Obj.audioPlylist(item[0]);
        });
      }
      if (extensive.length > 0) {
        extensive.map((index, item) => {
          item = $(extensive[index]);
          const options = $(item).data("options");
          H5AP_Plalist_Obj.bluePlaylist(item[0]);
        });
      }
    });
  });
})(jQuery);
