class H5AP_Plalist {
  /**
   *
   * @param {*} selector
   * @param {*} songs
   */
  audioPlylist(playlist, gutenberg = false) {
    const thisClass = this;
    const $ = jQuery;
    const songs = $(playlist).find(".h5ap_playlist ul li");
    const options = $(playlist).data("options");
    const totalSongs = songs.length - 1;
    let active = 0;
    const { initialVolume, controls = ["play", "progress", "current-time", "mute", "volume", "settings"], change_audio } = options;

    let i18n = {};
    if (typeof h5ap_i18n != "undefined") {
      i18n = h5ap_i18n;
    }
    const player = new Plyr($(playlist).find("audio"), {
      i18n,
      speed: { selected: 1, options: h5apPlayer?.speed.map(Number) },
      controls,
      listeners: {
        fastForward: (e) => {
          if (change_audio) {
            if (active < totalSongs) {
              this.setSource(player, active + 1, songs[active + 1], songs, true, false);
              active += 1;
            }
            return false;
          }
        },
        rewind: (e) => {
          if (change_audio) {
            if (active > 0) {
              this.setSource(player, active - 1, songs[active - 1], songs, true, false);
              active -= 1;
            }
            return false;
          }
        },
      },
    });

    //set Source for first song
    this.setSource(player, 0, songs?.[0], songs);

    //set intial volume
    player.volume = initialVolume / 100;

    player.on("ended", () => {
      if (active < totalSongs) {
        this.setSource(player, active + 1, songs[active + 1], songs, true, false);
        active += 1;
      }
    });

    // play song on click
    if (!gutenberg) {
      songs.each((index) => {
        const song = $(songs[index]);
        song.on("click", () => {
          if (index !== active) {
            active = index;
            this.setSource(player, index, songs[index], songs, !gutenberg);
          } else {
            this.setSource(player, index, songs[index], songs, false, true);
          }
        });
      });
    } else {
      $(playlist).on("click", "ul li", function () {
        const index = $(this).attr("index");
        if (index !== active) {
          active = index;
          thisClass.setSource(player, index, this, songs, !gutenberg);
        } else {
          thisClass.setSource(player, index, this, songs, false, true);
        }
      });
    }

    //disable other player on play this audio
    $(playlist)
      .find('.plyr__control[data-plyr="play"]')
      .on("click", () => {
        if (player.playing) {
          this.disableOtherPlayer(player);
        }
      });
  }

  /**
   *
   * @param {*} player
   * @param {*} songId
   * @param {*} song
   * @param {*} songs
   * @param {*} play
   * @param {Boolean} active true
   */
  setSource(player, songId, song, songs, play = false, active = false, suffle = false, repeat = false) {
    const $ = jQuery;
    songs.each(function () {
      $(this).removeClass("active playing");
    });
    $(song).addClass("active");
    let audioSrc = $(song).data("song");
    if (!audioSrc) {
      audioSrc = $(song).attr("data-song");
    }
    if (!active) {
      player.source = {
        title: "title",
        type: "audio",
        sources: [
          {
            src: audioSrc,
            type: "audio/mp3",
          },
        ],
      };
      $(".main-controls .dashicons-randomize").attr("active", suffle);
      $(".main-controls .dashicons-controls-repeat").attr("active", repeat);
      if (play) {
        this.disableOtherPlayer(player);
        $(song).addClass("playing");
      }
    } else {
      if (player.playing) {
        $(song).removeClass("playing");
        this.disableOtherPlayer(player, false);
      } else {
        $(song).addClass("playing");
        this.disableOtherPlayer(player, true);
      }
    }
  }

  /**
   *
   * @param {*} player
   */
  disableOtherPlayer(player, play = true) {
    const $ = jQuery;
    const players = $("audio");
    players.each(function () {
      $(this)[0].pause();
    });

    if (play) {
      player?.play();
    } else {
      player?.pause();
    }
  }

  /**
   *
   * @param {*} playlist
   */
  bluePlaylist(playlist, gutenberg = false) {
    const controls = `<div class="plyr__controls controls">
    <img class="poster" src="https://521dimensions.com/img/open-source/amplitudejs/album-art/we-are-to-answer.jpg" alt="">
    <div class="plyr_controls">
        <div class="progressbar plyr__controls">
            <div class="plyr__time plyr__time--current" aria-label="Current time">00:00</div>
            <div class="plyr__progress">
                <input data-plyr="seek" type="range" min="0" max="100" step="0.01" value="0" aria-label="Seek">
                <progress class="plyr__progress__buffer" min="0" max="100" value="0">% buffered</progress>
                <span role="tooltip" class="plyr__tooltip">00:00</span>
            </div>
            <div class="plyr__time plyr__time--duration" aria-label="Duration">00:00</div>
        </div>
        <div class="main-controls plyr__controls">
            <div class="repeat-shuffle ">
                <span class="plyr__control" active="true"><svg class="icon">
                <use xlink:href="#shuffle"></use>
              </svg></span>
                <span class="plyr__control" active="false"><svg class="icon">
                <use xlink:href="#exchange"></use>
              </svg></span>
            </div>
            <div class="re-ply-fast">
                <button type="button" class="plyr__control" data-plyr="rewind">
                    <svg role="presentation"><use xlink:href="#plyr-rewind"></use></svg>
                    <span class="plyr__tooltip" role="tooltip">Rewind {seektime} secs</span>
                </button>
                <button type="button" class="plyr__control" aria-label="Play, {title}" data-plyr="play">
                    <svg class="icon--pressed" role="presentation"><use xlink:href="#plyr-pause"></use></svg>
                    <svg class="icon--not-pressed" role="presentation"><use xlink:href="#plyr-play"></use></svg>
                    <span class="label--pressed plyr__tooltip" role="tooltip">Pause</span>
                    <span class="label--not-pressed plyr__tooltip" role="tooltip">Play</span>
                </button>
                <button type="button" class="plyr__control" data-plyr="fast-forward">
                    <svg role="presentation"><use xlink:href="#plyr-fast-forward"></use></svg>
                    <span class="plyr__tooltip" role="tooltip">Forward {seektime} secs</span>
                </button>
            </div>
            <div class="volume">
                <button type="button" class="plyr__control" aria-label="Mute" data-plyr="mute">
                    <svg class="icon--pressed" role="presentation"><use xlink:href="#plyr-muted"></use></svg>
                    <svg class="icon--not-pressed" role="presentation"><use xlink:href="#plyr-volume"></use></svg>
                    <span class="label--pressed plyr__tooltip" role="tooltip">Unmute</span>
                    <span class="label--not-pressed plyr__tooltip" role="tooltip">Mute</span>
                </button>
                <div class="plyr__volume">
                    <input data-plyr="volume" type="range" min="0" max="1" step="0.05" value="1" autocomplete="off" aria-label="Volume">
                </div>
            </div>
        </div>
    </div></div>`;

    const $ = jQuery;
    const thisClass = this;
    const songs = $(playlist).find(".right .hsong-item");
    const options = $(playlist).data("options");
    const totalSongs = songs.length - 1;
    let active = 0;
    const { initialVolume, change_audio } = options;

    //set duration to song list
    songs.map((index, item) => {
      const songSrc = $(item).data("song");
      const audio = document.createElement("audio");
      audio.src = songSrc;
      audio.addEventListener("loadedmetadata", () => {
        $(item).find(".time").text(this.toHHMMSS(audio.duration));
      });
    });

    // get translation word from localize script
    let i18n = {};
    if (typeof h5ap_i18n != "undefined") {
      i18n = h5ap_i18n;
    }

    //initialize player
    const player = new Plyr($(playlist).find("audio")[0], {
      controls,
      i18n,
      speed: { selected: 1, options: h5apPlayer?.speed.map(Number) },
      listeners: {
        fastForward: (e) => {
          if (change_audio) {
            if (active < totalSongs) {
              this.setSource(player, active + 1, songs[active + 1], songs, true, false);
              active += 1;
            }
            return false;
          }
        },
        rewind: (e) => {
          if (change_audio) {
            if (active > 0) {
              this.setSource(player, active - 1, songs[active - 1], songs, true, false);
              active -= 1;
            }
            return false;
          }
        },
      },
    });

    //set intial volume
    player.volume = initialVolume / 100;

    // setSource for first song
    this.setSource(player, 0, songs?.[0], songs);

    // set first song metadata
    this.setMetaData(playlist, $(playlist).find(".right .hsong-item")[0]);

    // enable/disbale suffle/repeat
    $(document).on("click", ".main-controls .dashicons", function () {
      $(this).attr("active", $(this).attr("active") === "true" ? "false" : "true");
    });

    // play next audio on audio ended
    player.on("ended", () => {
      const suffle = $(playlist).find(".main-controls .dashicons-randomize").attr("active");
      const repeat = $(playlist).find(".main-controls .dashicons-controls-repeat").attr("active");
      let suffleActive = this.getRandomInt(totalSongs);

      if (active < totalSongs) {
        // suffle song
        if (suffle === "true") {
          if (suffleActive == active) {
            suffleActive = this.getRandomInt(totalSongs);
          }
          active = suffleActive;
          this.setSource(player, active + 1, songs[suffleActive], songs, true, false, suffle, repeat);
          this.setMetaData(playlist, songs[suffleActive]);
        } else {
          this.setSource(player, active + 1, songs[active + 1], songs, true, false, suffle, repeat);
          this.setMetaData(playlist, songs[active + 1]);
          active += 1;
        }
      } else {
        if (repeat === "true") {
          this.setSource(player, active + 1, songs[0], songs, true, false, suffle, repeat);
          active = 0;
          this.setMetaData(playlist, songs[0]);
        }
      }
    });

    if (!gutenberg) {
      // play song on click
      songs.each((index, item) => {
        const song = $(songs[index]);
        song.on("click", () => {
          const suffle = $(playlist).find(".main-controls .dashicons-randomize").attr("active");
          const repeat = $(playlist).find(".main-controls .dashicons-controls-repeat").attr("active");
          if (index !== active) {
            active = index;
            this.setSource(player, index, songs[index], songs, !gutenberg, false, suffle, repeat);
          } else {
            this.setSource(player, index, songs[index], songs, false, true);
          }

          this.setMetaData(playlist, item);
        });
      });
    } else {
      $(playlist).on("click", ".hsong-item", function () {
        const suffle = $(playlist).find(".main-controls .dashicons-randomize").attr("active");
        const repeat = $(playlist).find(".main-controls .dashicons-controls-repeat").attr("active");
        const index = $(this).attr("index");
        if (index !== active) {
          active = index;
          thisClass.setSource(player, index, this, songs, false, false, suffle, repeat);
        } else {
          thisClass.setSource(player, index, this, songs, false, false);
        }

        thisClass.setMetaData(playlist, this);
      });
    }

    //disable other player on play this audio
    $(playlist)
      .find('.plyr__control[data-plyr="play"]')
      .on("click", () => {
        if (player.playing) {
          this.disableOtherPlayer(player);
        }
      });
  }

  setMetaData(playlist, selector) {
    const $ = jQuery;
    const title = $(selector).find("h3.title").text();
    const artist = $(selector).find("span.singer").text();
    let poster = $(selector).attr("data-poster");

    if (poster) $(playlist).find(".poster").attr("src", poster);
    $(playlist).find(".left .plyr__meta h2.title").text(title);
    $(playlist).find(".left .plyr__meta h3.artist").text(artist);
  }

  /**
   *
   * @param {*} time
   * @returns
   */
  toHHMMSS(time) {
    var sec_num = parseInt(time, 10); // don't forget the second param
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - hours * 3600) / 60);
    var seconds = sec_num - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return minutes + ":" + seconds;
  }

  getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  narrowControls() {
    const controls = `
    <div class="plyr__controls">
        <button type="button" class="plyr__control" data-plyr="restart">
            <svg role="presentation"><use xlink:href="#plyr-restart"></use></svg>
            <span class="plyr__tooltip" role="tooltip">Restart</span>
        </button>
        <button type="button" class="plyr__control" data-plyr="rewind">
            <svg role="presentation"><use xlink:href="#plyr-rewind"></use></svg>
            <span class="plyr__tooltip" role="tooltip">Rewind {seektime} secs</span>
        </button>
        <button type="button" class="plyr__control" aria-label="Play, {title}" data-plyr="play">
            <svg class="icon--pressed" role="presentation"><use xlink:href="#plyr-pause"></use></svg>
            <svg class="icon--not-pressed" role="presentation"><use xlink:href="#plyr-play"></use></svg>
            <span class="label--pressed plyr__tooltip" role="tooltip">Pause</span>
            <span class="label--not-pressed plyr__tooltip" role="tooltip">Play</span>
        </button>
        <button type="button" class="plyr__control" data-plyr="fast-forward">
            <svg role="presentation"><use xlink:href="#plyr-fast-forward"></use></svg>
            <span class="plyr__tooltip" role="tooltip">Forward {seektime} secs</span>
        </button>
        <div class="plyr__progress">
            <input data-plyr="seek" type="range" min="0" max="100" step="0.01" value="0" aria-label="Seek">
            <progress class="plyr__progress__buffer" min="0" max="100" value="0">% buffered</progress>
            <span role="tooltip" class="plyr__tooltip">00:00</span>
        </div>
        <div class="plyr__time plyr__time--current" aria-label="Current time">00:00</div>
        <div class="plyr__time plyr__time--duration" aria-label="Duration">00:00</div>
        <button type="button" class="plyr__control" aria-label="Mute" data-plyr="mute">
            <svg class="icon--pressed" role="presentation"><use xlink:href="#plyr-muted"></use></svg>
            <svg class="icon--not-pressed" role="presentation"><use xlink:href="#plyr-volume"></use></svg>
            <span class="label--pressed plyr__tooltip" role="tooltip">Unmute</span>
            <span class="label--not-pressed plyr__tooltip" role="tooltip">Mute</span>
        </button>
        <div class="plyr__volume">
            <input data-plyr="volume" type="range" min="0" max="1" step="0.05" value="1" autocomplete="off" aria-label="Volume">
        </div>
        <button type="button" class="plyr__control" data-plyr="captions">
            <svg class="icon--pressed" role="presentation"><use xlink:href="#plyr-captions-on"></use></svg>
            <svg class="icon--not-pressed" role="presentation"><use xlink:href="#plyr-captions-off"></use></svg>
            <span class="label--pressed plyr__tooltip" role="tooltip">Disable captions</span>
            <span class="label--not-pressed plyr__tooltip" role="tooltip">Enable captions</span>
        </button>
        <button type="button" class="plyr__control" data-plyr="fullscreen">
            <svg class="icon--pressed" role="presentation"><use xlink:href="#plyr-exit-fullscreen"></use></svg>
            <svg class="icon--not-pressed" role="presentation"><use xlink:href="#plyr-enter-fullscreen"></use></svg>
            <span class="label--pressed plyr__tooltip" role="tooltip">Exit fullscreen</span>
            <span class="label--not-pressed plyr__tooltip" role="tooltip">Enter fullscreen</span>
        </button>
    </div>
    `;
    return controls;
  }
}

export default H5AP_Plalist;
